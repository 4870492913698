'use client'

import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { useExperiment } from 'rvbr-www/red-testing'

import removeSpecialChars from '@equifax-ui/utils/formatters/removeSpecialChars'

import { axiosExternalInstance } from '@/services/axios'

import { newRelicNotifyError } from '@/utils/trackingNewRelic'

import { useProposalContext } from '@/context/ProposalContext'

import { useAnalytics } from '@/hooks/useAnalytics'

import { DOCUMENT_LABEL } from '@/constants'

export const usePaywall = () => {
  const {
    control,
    setValue,
    watch,
    formState: { isValid }
  } = useForm({
    mode: 'onChange'
  })

  const { activateExperiment } = useExperiment('paywall')
  const { setIsPaywallOpen } = useProposalContext()

  const { getTrackingData } = useAnalytics()

  useEffect(() => {
    const document = sessionStorage.getItem(DOCUMENT_LABEL)

    setValue('cnpj', document, { shouldValidate: document })
    activateExperiment()
  }, [])

  const closeModal = () => {
    sessionStorage.setItem(DOCUMENT_LABEL, watch('cnpj'))
    setIsPaywallOpen(false)
  }

  const sendConversion = async () => {
    const cnpj = removeSpecialChars(watch('cnpj'))

    const trackingData = await getTrackingData()
    const trackingRoute = `${api.default.baseUrl}/${api.default.version}/softlead` // eslint-disable-line no-undef

    try {
      await axiosExternalInstance.post(
        trackingRoute,
        {
          cnpj,
          analytics: trackingData
        },
        { headers: { 'app-key': APP_KEY_WPP_REDIRECT } } // eslint-disable-line no-undef
      )
    } catch (error) {
      newRelicNotifyError(`Error sendConversion: ${error}`)
    }
  }

  const onClickWhatsapp = async () => {
    await sendConversion()

    closeModal()
  }

  return {
    control,
    isValid,
    onClickWhatsapp,
    closeModal
  }
}
