import {
  WHATSAPP_BASE_URL,
  WHATSAPP_PHONE_NUMBERS,
  WHATSAPP_URL_PARAMS
} from './whatsapp'

export const CUSTOM_PLAN = {
  id: 'custom',
  type: 'postpaid',
  is_best_selling: false,
  name: 'Personalizado',
  label: 'Plano Personalizado',
  highlight_text: (
    <>
      <b>Precisa de mais consultas?</b>
    </>
  ),
  text: 'Oferecemos planos exclusivos para atender às necessidades da sua empresa.',
  url: `${WHATSAPP_BASE_URL}${WHATSAPP_PHONE_NUMBERS['organic']}${WHATSAPP_URL_PARAMS}`
}

export const AFFILIATE_PLAN = {
  id: 'affiliate',
  type: 'postpaid',
  is_recommended: false,
  name: 'Master',
  label: 'Plano Master',
  consultations_per_month: '80',
  monthly_price: 'R$ 600,00',
  highlight_text: (
    <>
      <b>R$ 600,00</b> / mês
    </>
  ),
  text: (
    <>
      Consultas a partir de <b>R$ 6,88</b>
    </>
  ),
  cpf_details: [
    { name: 'Consulta por CPF', value: 'Valor' },
    { name: 'Básica', value: 'R$ 8,61' },
    { name: 'Intermediária', value: 'R$ 12,89' },
    { name: 'Completa', value: 'R$ 16,20' }
  ],
  cnpj_details: [
    { name: 'Consulta por CNPJ', value: 'Valor' },
    { name: 'Básica', value: 'R$ 6,89' },
    { name: 'Intermediária', value: 'R$ 16,20' },
    { name: 'Completa', value: 'R$ 24,30' }
  ]
}

export const PRE_PAID_PLANS = [
  {
    id: 'pre-1',
    type: 'prepaid',
    highlight_text: <b>R$ 40</b>,
    text: (
      <>
        Consultas a partir de <b>R$ 10,40</b>
      </>
    ),
    label: 'Recarga',
    url: 'https://loja.boavistaservicos.com.br/?add-to-cart=75'
  },
  {
    id: 'pre-2',
    type: 'prepaid',
    highlight_text: <b>R$ 70</b>,
    text: (
      <>
        Consultas a partir de <b>R$ 10,40</b>
      </>
    ),
    label: 'Recarga',
    url: 'https://loja.boavistaservicos.com.br/?add-to-cart=76'
  },
  {
    id: 'pre-3',
    type: 'prepaid',
    highlight_text: <b>R$ 130</b>,
    text: (
      <>
        Consultas a partir de <b>R$ 10,40</b>
      </>
    ),
    label: 'Recarga',
    url: 'https://loja.boavistaservicos.com.br/?add-to-cart=77'
  },
  {
    id: 'pre-4',
    type: 'prepaid',
    highlight_text: <b>R$ 250</b>,
    text: (
      <>
        Consultas a partir de <b>R$ 10,40</b>
      </>
    ),
    label: 'Recarga',
    url: 'https://loja.boavistaservicos.com.br/?add-to-cart=78'
  },
  {
    id: 'pre-5',
    type: 'prepaid',
    highlight_text: <b>R$ 500</b>,
    text: (
      <>
        Consultas a partir de <b>R$ 10,40</b>
      </>
    ),
    label: 'Recarga',
    url: 'https://loja.boavistaservicos.com.br/?add-to-cart=205'
  }
]

export const POST_PAID_PLANS = [
  {
    id: 'basic',
    type: 'postpaid',
    is_best_selling: false,
    name: 'Econômico',
    label: 'Plano Econômico',
    consultations_per_month: '10',
    monthly_price: 'R$ 99,90',
    highlight_text: (
      <>
        <b>R$ 99,90</b> / mês
      </>
    ),
    text: (
      <>
        Consultas a partir de <b>R$ 10,29</b>
      </>
    ),
    cpf_details: [
      { name: 'Consulta por CPF', value: 'Valor' },
      { name: 'Básica', value: 'R$ 12,86' },
      { name: 'Intermediária', value: 'R$ 19,65' },
      { name: 'Completa', value: 'R$ 24,20' }
    ],
    cnpj_details: [
      { name: 'Consulta por CNPJ', value: 'Valor' },
      { name: 'Básica', value: 'R$ 10,29' },
      { name: 'Intermediária', value: 'R$ 24,20' },
      { name: 'Completa', value: 'R$ 36,30' }
    ]
  },
  {
    id: 'intermediate',
    type: 'postpaid',
    is_best_selling: false,
    name: 'Mais',
    label: 'Plano Mais',
    consultations_per_month: '15',
    monthly_price: 'R$ 149,90',
    discount: '9%',
    highlight_text: (
      <>
        <b>R$ 149,90</b> / mês
      </>
    ),
    text: (
      <>
        Consultas a partir de <b>R$ 9,35</b>
      </>
    ),
    cpf_details: [
      { name: 'Consulta por CPF', value: 'Valor' },
      { name: 'Básica', value: 'R$ 11,69' },
      { name: 'Intermediária', value: 'R$ 17,68' },
      { name: 'Completa', value: 'R$ 22,00' }
    ],
    cnpj_details: [
      { name: 'Consulta por CNPJ', value: 'Valor' },
      { name: 'Básica', value: 'R$ 9,35' },
      { name: 'Intermediária', value: 'R$ 22,00' },
      { name: 'Completa', value: 'R$ 33,00' }
    ]
  },
  {
    id: 'advanced',
    type: 'postpaid',
    is_best_selling: true,
    name: 'Elite',
    label: 'Plano Elite',
    consultations_per_month: '30',
    monthly_price: 'R$ 249,90',
    discount: '17%',
    highlight_text: (
      <>
        <b>R$ 249,90</b> / mês
      </>
    ),
    text: (
      <>
        Consultas a partir de <b>R$ 8,50</b>
      </>
    ),
    cpf_details: [
      { name: 'Consulta por CPF', value: 'Valor' },
      { name: 'Básica', value: 'R$ 10,63' },
      { name: 'Intermediária', value: 'R$ 15,91' },
      { name: 'Completa', value: 'R$ 20,00' }
    ],
    cnpj_details: [
      { name: 'Consulta por CNPJ', value: 'Valor' },
      { name: 'Básica', value: 'R$ 8,50' },
      { name: 'Intermediária', value: 'R$ 20,00' },
      { name: 'Completa', value: 'R$ 30,00' }
    ]
  },
  {
    id: 'premium',
    type: 'postpaid',
    is_best_selling: false,
    name: 'Exclusivo',
    label: 'Plano Exclusivo',
    consultations_per_month: '50',
    monthly_price: 'R$ 399,00',
    discount: '25%',
    highlight_text: (
      <>
        <b>R$ 399,00</b> / mês
      </>
    ),
    text: (
      <>
        Consultas a partir de <b>R$ 7,65</b>
      </>
    ),
    cpf_details: [
      { name: 'Consulta por CPF', value: 'Valor' },
      { name: 'Básica', value: 'R$ 9,56' },
      { name: 'Intermediária', value: 'R$ 14,32' },
      { name: 'Completa', value: 'R$ 18,00' }
    ],
    cnpj_details: [
      { name: 'Consulta por CNPJ', value: 'Valor' },
      { name: 'Básica', value: 'R$ 7,65' },
      { name: 'Intermediária', value: 'R$ 18,00' },
      { name: 'Completa', value: 'R$ 27,00' }
    ]
  }
]

export const PLANS_DATA = { prepaid: PRE_PAID_PLANS, postpaid: POST_PAID_PLANS }
