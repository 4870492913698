export const STEPS = {
  softlead: 'dados-basicos',
  'otp-verification': 'verificacao',
  plans: 'planos',
  business: 'dados-empresa',
  personal: 'dados-adicionais',
  summary: 'conclusao',
  client: 'cliente',
  default: 'dados-recebidos',
  hunter: 'aguardando-contato',
  unavailable: 'indisponivel',
  'not-enriched': 'dados-recebidos',
  'lead-duplicated': 'contratante'
}

export const FEEDBACK_STEPS = [
  'client',
  'default',
  'not-enriched',
  'hunter',
  'unavailable',
  'summary',
  'lead-duplicated'
]
