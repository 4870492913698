import { CLIENT_PORTAL_URL, POSITIVE_CONSUMER_URL } from '@/constants/links'

const isOrganic = NEXT_PUBLIC_BUILD_TYPE === 'organic' // eslint-disable-line no-undef

export const SECTION_ANCHORS = [
  { label: 'Serviços ', path: '#servicos' },
  { label: 'Ajuda ', path: '#ajuda' }
]

export const LP_URLS = [
  {
    rel: 'noreferrer',
    label: 'Planos Pós-Pago',
    path: `${urlEquifaxOrganic}/planos/` // eslint-disable-line no-undef
  },
  {
    rel: 'noreferrer',
    label: 'Recarga Pré-Pago',
    path: `${urlEquifaxOrganic}/consultas-pre-pago/` // eslint-disable-line no-undef
  },
  {
    rel: 'noreferrer',
    label: 'Soluções',
    path: `${urlEquifaxOrganic}/solucoes-para-empresas/` // eslint-disable-line no-undef
  },
  {
    target: '_blank',
    rel: 'noreferrer',
    label: 'Antifraude',
    path: 'https://www.konduto.com/'
  }
]

export const NAVIGATION_LINKS = isOrganic ? LP_URLS : SECTION_ANCHORS

export const POSITIVE_CONSUMER_LINK_DATA = {
  target: '_blank',
  rel: 'noreferrer',
  label: 'Consultar meu CPF',
  path: POSITIVE_CONSUMER_URL
}

export const HEADER_NAVIGATION = [
  {
    id: 'business',
    category: 'Para sua empresa',
    links: NAVIGATION_LINKS
  },
  {
    id: 'consumer',
    category: 'Para você',
    links: [POSITIVE_CONSUMER_LINK_DATA]
  }
]

export const BUTTONS_DATA = {
  paid: [
    {
      target: '_blank',
      rel: 'noreferrer',
      id: 'header-login-button',
      text: 'Entrar',
      icon: 'equifax-user',
      variant: 'secondary',
      url: CLIENT_PORTAL_URL
    }
  ],
  organic: [
    { id: 'header-help-button', text: 'Ajuda', variant: 'link', url: '#ajuda' },
    {
      target: '_blank',
      rel: 'noreferrer',
      id: 'header-login-button',
      text: 'Entrar',
      icon: 'equifax-user',
      variant: 'secondary',
      url: CLIENT_PORTAL_URL
    }
  ]
}
