'use client'

import { useState } from 'react'

import { usePlans } from '@/hooks/usePlans'

import { Plan } from '@/components'

import styles from './PlanList.module.scss'

export const PlanList = ({
  children,
  selectedPlan,
  setSelectedPlan,
  onClickCTA
}) => {
  const [expandedPlans, setExpandedPlans] = useState({})

  const { postpaidPlans } = usePlans()

  return (
    <div className={styles.plans}>
      {postpaidPlans.map((plan) => (
        <Plan
          plan={plan}
          key={`plans-${plan.id}`}
          selectedPlan={selectedPlan || 'advanced'}
          expandedPlans={expandedPlans}
          setExpandedPlans={setExpandedPlans}
          onClickCard={setSelectedPlan}
          onClickCTA={onClickCTA}
        >
          {children}
        </Plan>
      ))}
    </div>
  )
}
